<template>
  <widget-container :widget="widget">
    <l-map
      ref="map"
      class="imagemap-container"
      :min-zoom="minZoom"
      :max-zoom="maxZoom"
      :zoom="zoom"
      :crs="crs"
      @click="clicked"
      @update:zoom="zoomed"
      @ready="updateLayout"
    >
      <l-image-overlay
        v-if="imageUrl !== undefined"
        ref="iOverlay"
        :url="imageUrl"
        :bounds="bounds"
      />

      <div :key="reactivity">
        <l-marker
          v-for="(item, i) in markers"
          :key="i"
          :lat-lng="item.latlng"
          :icon="markerIcon(item.metaId)"
          @click="markerClick(item)"
        >
          <l-tooltip
            :options="{
              permanent: true,
              reactive: true,
              direction: 'center',
              opacity: 1,
            }"
            :key="item.data + item.metaId"
          >
            <div :style="tooltipStyle(item.metaId)">
              {{ markerValue(item.metaId) }}
            </div>
          </l-tooltip>
        </l-marker>

        <v-dialog v-model="infoDialog" max-width="700">
          <v-toolbar dark class="mb-0">
            <v-btn
              icon
              dark
              @click="
                () => {
                  infoDialog = false;
                  selectedMeta = undefined;
                }
              "
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <widget-image-map-dialog-content
            v-if="selectedMeta"
            :meta-id="selectedMeta"
            :widget="widget"
          />
        </v-dialog>
      </div>
    </l-map>
  </widget-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import imageMapMixin from "../../../_helpers/imageMapMixin";
import WidgetContainer from "./WidgetContainer.vue";
import WidgetImageMapDialogContent from "./ImageMap/WidgetImageMapDialogContent.vue";

import "leaflet/dist/leaflet.css";
import { CRS } from "leaflet";
import { LMap, LImageOverlay, LMarker, LTooltip } from "vue2-leaflet";
import widgetMixin from "../../../_helpers/widgetMixin";

export default {
  name: "WidgetImageMap",
  props: {
    widget: {
      default: undefined,
    },
    editMode: {
      default: false,
    },
  },

  components: {
    WidgetContainer,
    LMap,
    LImageOverlay,
    LMarker,
    LTooltip,
    WidgetImageMapDialogContent,
  },

  mixins: [imageMapMixin, widgetMixin],

  data() {
    return {
      selectedMeta: undefined,
      infoDialog: false,
      crs: CRS.Simple,
      zoom: 10,
      reactivity: 1,
      imageUrl: undefined,
      bounds: [
        [0, 0],
        [720, 970],
      ],
    };
  },

  computed: {
    ...mapState("configuration", ["SO_API_BASE_URL"]),
  },

  methods: {
    ...mapActions("configuration", ["getBaseUrl"]),
    ...mapActions("tag", ["getDecoders"]),

    clicked(e) {
      this.$emit("map-click", e);
    },

    markerClick(e) {
      this.infoDialog = true;
      this.selectedMeta = e.metaId;
      this.$emit("marker-click", e);
    },

    zoomed(e) {
      e;
    },

    openEdit() {
      this.$refs.editor.open();
    },

    setDefault() {
      this.imageUrl = "missing-img.png";
      this.bounds[1][0] = 480;
      this.bounds[1][1] = 750;
    },

    async updateLayout() {
      setTimeout(() => {
        this.$refs.map.mapObject.invalidateSize();
        this.$refs.map.fitBounds(this.bounds);
      }, 500);
    },

    _updateMarkers() {
      this.reactivity++;
    },

    async setLayout() {
      if (this?.widget?.imagePath === undefined) return;

      await this.getBaseUrl();

      this.imageUrl = `${this.SO_API_BASE_URL}/api/image/${this.widget.imagePath}`;
      let ret = await this.getImageMeta(this.imageUrl);
      this.bounds[1][0] = ret.height;
      this.bounds[1][1] = ret.width;

      // Set zoom parameters
      this.$refs.map.mapObject.options.zoomSnap = 0.05;
      this.$refs.map.mapObject.options.wheelPxPerZoomLevel = 100;
      this.$refs.map.mapObject.options.wheelDebounceTime = 5;

      this.$refs.iOverlay.setBounds(this.bounds);

      await this.updateLayout();
    },
  },

  watch: {
    infoDialog(val) {
      if (!val) this.selectedMeta = undefined;
    },
  },

  async mounted() {
    await this.setLayout();
    this.setMarkers();
    this.getDecoders();
  },
};
</script>

<style>
.imagemap-container {
  height: 100%;
  width: 100%;
  max-height: 100%;
  z-index: 4;
}

.leaflet-div-icon {
  background: transparent !important;
  border: transparent !important;
}

.leaflet-tooltip {
  background: transparent !important;
  border: transparent !important;
  box-shadow: none;
  font-size: 14px;
  font-weight: bolder;
  color: white;
}
</style>
